/*--------------------------------------------------
Randomizer
--------------------------------------------------*/

.gss-randomizer {
    background-color: rgb(241, 114, 114);
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.randomizer-display {
    padding-top: 1.2rem;
}

.randomizer-display img {
    width: 100%;
    height: 40vh;
}

.randomizer-display h2 {
    text-align: center;
    font-weight: bold;
}

.stop-button {
    margin-top: 1.2rem;
    padding: 0.8rem;
    width: 25%;
    font-size: 2rem;
    font-weight: bold;
    background-color: lightblue;
    border: none;
}

.call {
    margin-top: 5rem;
}

.call button {
    margin-top: 1.2rem;
    padding: 0.8rem;
    width: 100%;
    font-size: 2rem;
    font-weight: bold;
    background-color: lightgreen;
    border: none;
}